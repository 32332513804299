<template>
  <div class="interview_promo">
    <div class="interview_promo-wrapper section_wrapper">
      <div class="interview_promo-title" v-if="title"
           data-aos="fade-left" data-aos-delay="400">{{ title }}</div>
      <div class="interview_promo-detail" v-if="youtubeUrl"
           data-aos="fade-down"
      >
        <a :href="youtubeUrl" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
            <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
          </svg>
          <p>{{ youtubeTitle }}</p>
        </a>
      </div>
      <div class="interview_promo-description" v-if="subtitle"
           data-aos="fade-right" data-aos-delay="800"
      >{{ subtitle }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InterviewPromoBar",
  props: {
    youtubeTitle: {
      type: String,
      default: 'YouTube',
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    youtubeUrl: {
      type: String,
      required: false,
    },
  },
}
</script>

<style scoped>
.interview_promo {
  width: 100%;
  min-height: 80px;
  background-image: radial-gradient(circle, orange, darkorange);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.interview_promo-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.interview_promo-title {
  width: calc(50% - 60px);
  height: auto;
  text-align: right;
  color: yellow;
  font-size: 2rem;
  line-height: 2rem;
}
.interview_promo-description {
  width: calc(50% - 120px);
  height: auto;
  text-align: left;
  color: yellow;
  font-size: 1.2rem;
  line-height: 1.6rem;
}
.interview_promo-detail {
  width: 120px;
  height: 120px;
  background-image: radial-gradient(circle, mediumblue, darkblue);
  border-radius: 100px;
  margin: 30px;
  color: orange;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
  transition: all linear .3s;
}
.interview_promo-detail:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .4);
  transition: all linear .3s;
  transform: translateY(-2px);
}
.interview_promo-detail a {
  color: white;
  font-size: .8rem;
  line-height: .8rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 100px;
  overflow: hidden;
}
.interview_promo-detail a:before {
  content: '';
  width: 60%;
  height: 100%;
  max-height: 0;
  position: absolute;
  background-image: linear-gradient(to bottom, transparent, rgba(255, 255, 255, .3));
  border-radius: 50% 50% 100% 100%;
  bottom: 3px;
  transition: max-height ease-in-out .1s;
}
.interview_promo-detail:hover a:before {
  max-height: 40%;
  transition: max-height ease-in-out .2s;
}
</style>
