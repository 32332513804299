<template>
  <div class="interview_list">
    <div class="section_wrapper">
      <ul>
        <li v-for="movie in movies"
            :key="`${movie.id.videoId}_item`"
            @click="playMovie(movie.id.videoId)"
            data-aos="fade-down"
        >
          <div class="interview_list-wrapper">
            <div class="interview_highlight-list_image">
              <InterviewMovie :movie="movie" :ref="`movieId${movie.id.videoId}`" size="medium" />
            </div>
            <div class="interview_highlight-list_item">
              <div class="title">{{ movie.snippet.title }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import InterviewMovie from "@/components/InterviewMovie";

export default {
  name: "InterviewMoviesList",
  components: {InterviewMovie},
  props: {
    movies: {
      type: Array,
      required: false,
    },
  },
  methods: {
    playMovie(movieId) {
      const elemObject = `movieId${movieId}`;
      this.$refs[elemObject][0].execPlay();
    },
  },
}
</script>

<style scoped>
.interview_list {
  position: relative;
  width: 100%;
  height: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
}
.interview_list ul {
  list-style: none;
  margin-left: -10px;
  width: calc(100% + 40px);
  padding: 10px 0;
  height: auto;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}
.interview_list ul li {
  position: relative;
  width: 33%;
  height: auto;
  display: inline-flex;
  padding: 10px 10px;
  cursor: pointer;
}
.interview_list ul li .interview_list-wrapper {
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;
}
.interview_list ul li .interview_list-wrapper:before {
  content: '';
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  background-color: lightgray;
  position: absolute;
  left: -4px;
  top: -4px;
  border-radius: 12px;
  max-width: 0;
  transition: max-width ease-in-out .3s;
}
.interview_list ul li:hover .interview_list-wrapper:before {
  max-width: 100%;
  transition: max-width ease-in-out .3s;
}
.interview_highlight-list_image {
  width: 50%;
  height: auto;
  z-index: 1;
}
@media screen and (max-width: 800px) {
  .interview_list ul li {
    width: 100%;
  }
  .interview_list ul {
    width: calc(100% + 30px);
  }
}
.interview_highlight-list_item {
  flex: 1;
  display: inline-flex;
  justify-content: stretch;
  align-items: center;
  padding: 10px;
  z-index: 1;
}
.interview_highlight-list_item .title {
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.2rem;
  letter-spacing: -1px;
  font-weight: 400;
}
</style>
