<template>
  <InterviewHeader
    title="Entrevistas"
    youtube-title="Clube92FMVotuporanga"
    :youtube-url="urlYoutube"
    data-aos="zoom-in"
  />
  <InterviewMoviesHighlight
    :movies="moviesHighLighted"
  />
  <InterviewHeader
      subtitle="Recentes"
      class="margin_top"
      data-aos="zoom-in"
  />
  <InterviewMoviesList
      :movies="moviesList"
  />
  <InterviewPromoBar
      title="Outras entrevistas e muito mais..."
      subtitle="Curta e compartilhe a vontade nosso conteúdo e se mantenha super informado."
      youtube-title="Clube92..."
      :youtube-url="urlYoutube"
      class="margin_top"
  />
</template>

<script>
import movies from './datasource/movies';
import InterviewHeader from "@/components/InverviewHeader";
import InterviewMoviesHighlight from "@/components/InterviewMoviesHighlight";
import InterviewMoviesList from "@/components/InterviewMoviesList";
import AOS from 'aos';
import 'aos/dist/aos.css';
import InterviewPromoBar from "@/components/InterviewPromoBar";

export default {
  name: 'App',
  components: {
    InterviewPromoBar,
    InterviewMoviesList,
    InterviewMoviesHighlight,
    InterviewHeader,
  },
  data() {
    return {
      urlYoutube: 'https://www.youtube.com/c/Clube92FMVotuporangaSP/videos',
      movies: [],
      selected: null,
      playing: null,
    };
  },
  async mounted() {
    AOS.init();
    this.movies = await movies();
  },
  computed: {
    moviesHighLighted() {
      const { items } = this.movies;
      if (!items) return [];
      return items.slice(0, 3);
    },
    moviesList() {
      const { items } = this.movies;
      if (!items) return [];
      return items.slice(3);
    },
  },
  methods: {
    markWithSelected(movieId) {
      this.selected = movieId;
    },
    playMovie(movieId) {
      this.playing = movieId;
    },
  },
}
</script>

<style>
#app {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.section_wrapper {
  max-width: 1100px;
}
@media screen and (max-width: 800px) {
  .section_wrapper {
    max-width: 100%;
    padding: 0 20px;
  }
}
.margin_top {
  margin-top: 30px;
}


/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
