<template>
  <div class="movie_image-wrapper" v-if="movie" @click="execPlay">
    <div class="movie_image-image" v-if="!playing">
      <img v-if="size === 'default'" :src="movie.snippet.thumbnails.default.url" :alt="movie.snippet.title" :title="movie.snippet.title" loading="lazy" />
      <img v-if="size === 'medium'" :src="movie.snippet.thumbnails.medium.url" :alt="movie.snippet.title" :title="movie.snippet.title" loading="lazy" />
      <img v-if="size === 'hight'" :src="movie.snippet.thumbnails.high.url" :alt="movie.snippet.title" :title="movie.snippet.title" loading="lazy" />
      <div class="movie_image-image-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
        </svg>
      </div>
    </div>
    <div class="movie_image-video" v-else></div>
  </div>
</template>

<script>
export default {
  name: "InterviewMovie",
  data() {
    return {
      playing: false,
    };
  },
  props: {
    movie: {
      type: Object,
      required: false,
    },
    size: {
      type: String,
      default: 'default',
    },
  },
  methods: {
    execPlay() {
      window.open(`https://www.youtube.com/watch?v=${this.movie.id.videoId}&t=4s`, '_blank');
    },
  },
}
</script>

<style scoped>
.movie_image-wrapper {
  width: 100%;
  height: auto;
  padding: 0 0 77% 0;
  position: relative;
}
.movie_image-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: stretch;
  align-items: stretch;
  cursor: pointer;
  border-radius: 12px;
  overflow: hidden;
}
.movie_image-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.movie_image-image .movie_image-image-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .2);
  color: white;
}

.movie_image-image .movie_image-image-icon svg {
  width: 64px;
  height: 64px;
  opacity: .8;
  transition: opacity linear .6s;
}
.movie_image-image:hover .movie_image-image-icon svg {
  opacity: 1;
  transition: opacity linear .6s;
}

.movie_image-video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: darkslategrey;
}
</style>
