<template>
  <div class="interview_highlight" v-if="movies.length">
    <div class="interview_highlight-wrapper section_wrapper"
         data-aos="zoom-in">
      <div class="interview_highlight-movies" v-if="movieSelected">
        <ul>
          <Transition mode="out-in" appear name="slide-fade">
            <li :key="movieSelected.id.videoId">
              <InterviewMovie :movie="movieSelected" size="hight" />
            </li>
          </Transition>
        </ul>
        <div class="interview_highlight-movies-after" @click="markAfterSelected">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-chevron-compact-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"/>
          </svg>
        </div>
        <div class="interview_highlight-movies-before" @click="markBeforeSelected">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-chevron-compact-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"/>
          </svg>
        </div>
      </div>
      <div class="interview_highlight-list" v-if="movies.length">
        <ul>
          <li :class="{ active: movie.id.videoId === selected }"
              v-for="(movie, index) in movies"
              :key="`${movie.id.videoId}_hl_content`"
              @click="markWithSelected(movie.id.videoId)"
          >
            <div class="interview_highlight-list_item"
                 data-aos="fade-left"
                 :data-aos-delay="index * 300 + 300">
              <div class="title">{{ movie.snippet.title }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import InterviewMovie from "@/components/InterviewMovie";
export default {
  name: "InterviewMoviesHighlight",
  components: {InterviewMovie},
  data() {
    return {
      selected: null,
      playing: null,
    };
  },
  watch: {
    movies: function () {
      if (this.selected === null) {
        this.startSelectedMovie();
      }
    },
  },
  mounted() {
    this.startSelectedMovie();
  },
  props: {
    movies: {
      type: Array,
      required: false,
    },
  },
  computed: {
    movieSelected() {
      return this.movies.find(movie => movie.id.videoId === this.selected);
    },
  },
  methods: {
    startSelectedMovie() {
      const { movies } = this;
      if (!movies.length) return;
      this.markWithSelected(movies[0].id.videoId);
    },
    markWithSelected(movieId) {
      this.selected = movieId;
    },
    markAfterSelected() {
      const { movies } = this;
      const movieIndex = movies.findIndex(movie => movie.id.videoId === this.selected);
      this.markWithSelectedByIndex(movieIndex + 1);
    },
    markBeforeSelected() {
      const { movies } = this;
      const movieIndex = movies.findIndex(movie => movie.id.videoId === this.selected);
      this.markWithSelectedByIndex(movieIndex - 1);
    },
    markWithSelectedByIndex(movieIndex) {
      if (movieIndex < 0) {
        this.markWithSelected(this.movies[this.movies.length - 1].id.videoId);
      } else if (movieIndex > this.movies.length - 1) {
        this.markWithSelected(this.movies[0].id.videoId);
      } else {
        this.markWithSelected(this.movies[movieIndex].id.videoId);
      }
    },
  },
}
</script>

<style scoped>
.interview_highlight {
  position: relative;
  width: 100%;
  height: 400px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  background-color: black;
}
.interview_highlight-wrapper {
  display: inline-flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.interview_highlight-movies {
  width: 711px;
  height: 400px;
  position: relative;
  background-color: rgba(255, 255, 255, .15);
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.interview_highlight-movies ul {
  padding: 0;
  margin: 0;
  position: relative;
  list-style: none;
  width: calc(100% - 120px);
  height: calc(100% - 60px);
  overflow: hidden;
}
.interview_highlight-movies-before {
  height: 100%;
  width: 60px;
  position: absolute;
  left: 0;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  opacity: .2;
  transition: opacity .3s linear;
  cursor: pointer;
}
.interview_highlight-movies-after {
  height: 100%;
  width: 60px;
  position: absolute;
  right: 0;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  opacity: .2;
  transition: opacity .3s linear;
  cursor: pointer;
}
.interview_highlight-movies-before:hover,
.interview_highlight-movies-after:hover {
  opacity: .4;
  transition: opacity .3s linear;
}
.interview_highlight-movies ul li {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.interview_highlight-list {
  flex: 1;
  height: 400px;
}
.interview_highlight-list ul {
  padding: 30px 0;
  margin: 0;
  flex: 1;
  position: relative;
  list-style: none;
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.interview_highlight-list ul li {
  width: 100%;
  height: 33.33%;
  display: inline-flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  padding: 20px 20px 20px 30px;
  text-align: left;
  color: white;
  letter-spacing: -1px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  border-left: 6px solid transparent;
  cursor: pointer;
  position: relative;
}
.interview_highlight-list ul li:last-child {
  border-bottom: none;
}
.interview_highlight-list ul li:before {
  content: '';
  width: 8px;
  height: 100%;
  max-height: 8px;
  position: absolute;
  background-color: darkblue;
  left: 8px;
  border-radius: 4px;
  transition: max-height ease-in-out .3s;
}
.interview_highlight-list ul li.active:before {
  height: 100%;
  max-height: 100%;
  transition: max-height ease-in-out .3s;
}


@media screen and (max-width: 800px) {
  .interview_highlight {
    margin-left: 0;
    width: calc(100% + 50px);
    height: auto;
  }
  .interview_highlight-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .interview_highlight-movies {
    width: 100%;
    height: 200px;
  }
  .interview_highlight-movies ul {
    width: calc(100% - 60px);
    height: 100%;
  }
  .interview_highlight-movies-before {
    width: 30px;
  }
  .interview_highlight-movies-after {
    width: 30px;
  }
}
</style>
